import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";

// import AuthVerify from "./common/auth-verify";
import Navbar from "./components/Navbar";
import Music from "./components/music.component";
import KingCup from "./components/Trinkspiele/kingCup.component";
import Shittty from "./components/Trinkspiele/shitty.component";
import Beerpong from "./components/Trinkspiele/beerpong.component";
import Drinkhelper from "./components/drinkhelper";
import HigherOrLower from "./components/Trinkspiele/higherOrLower";
import Lieing from "./components/Trinkspiele/lieing";
import LieingCards from "./components/Trinkspiele/lieing_Cards";
import LieingCubes from "./components/Trinkspiele/lieing_Cubes";
import Laughfool from "./components/Trinkspiele/laughtfools";
import SaufAlone from "./components/saufhelper/drinkhelperOne";
import SaufTwo from "./components/saufhelper/drinkhelperTwo";
import SaufWithYourFriends from "./components/saufhelper/drinkhelperMore";
import Holzfaellern from "./components/Trinkspiele/holzfaellern";
import DontDrinkAndDrive from "./components/Trinkspiele/dontDrinkAndDrive";
import Quizzler from "./components/Trinkspiele/quizzduell";
import RageCage from "./components/Trinkspiele/rageCage";
import MusicNoteQuiz from "./components/Trinkspiele/music.component";

class App extends Component {

  render() {
    return (
      <>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Drinkhelper/>} />
          <Route path="/music" element={<Music/>} />
          <Route path="/kingCup" element={<KingCup/>} />
          <Route path="/shitty" element={<Shittty/>} />
          <Route path="/beerpong" element={<Beerpong/>} />
          <Route path="/higherOrLower" element={<HigherOrLower/>} />
          <Route path="/lieing" element={<Lieing/>} />
          <Route path="/lieingCards" element={<LieingCards/>} />
          <Route path="/lieingCubes" element={<LieingCubes/>} />
          <Route path="/laughfool" element={<Laughfool/>} />
          <Route path="/saufalone" element={<SaufAlone/>} />
          <Route path="/sauftwo" element={<SaufTwo/>} />
          <Route path="/saufwithyourfriends" element={<SaufWithYourFriends/>} />
          <Route path="/saufwithyourfriends" element={<SaufWithYourFriends/>} />
          <Route path="/holzfaellern" element={<Holzfaellern/>} />
          <Route path="/dontdrinkanddrive" element={<DontDrinkAndDrive/>} />
          <Route path="/quizzler" element={<Quizzler/>} />
          <Route path="/rageCage" element={<RageCage/>} />
          <Route path="/sheetMusic" element={<MusicNoteQuiz/>}/>
        </Routes>
      </>
    );
  }
}

export default App;
