import React from 'react'


function DontDrinkAndDrive(){
     return (
         <div className='mainDiv'>
            <h1>Don't drink and drive</h1>
            <p>Requirements:
                <ul>
                    <li>
                        Drinks        
                    </li>
                    <li>
                        A console with a racing game (preferably Mario kart)
                    </li>
                </ul>
                How to play:
                <br />
                You just race, preferably all in one match. The goal is, as usual, to finish as fast as possible.
                <br />
                Only limitation, you have to finish your drink, before you finish the cup. Everytime you drink, you have to stop at the side of the road, because don't drink and drive.
                <br />
                Last person is a slow turd, or something. You determine the punishment, I dofädakl't care.
            </p>
        </div>
    )
}

export default DontDrinkAndDrive