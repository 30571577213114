import React from 'react'
import "./Trinkspiele/css/drinkhelper.css"

const Drinkhelper = () => {
     return (
         <div className='mainDiv'>
            <h1>Drinking Helper:</h1>
            <p>You want to drink, but you don't know what game to play? Don't worry, I've got ya.</p>

            <h4>How many players are you:</h4>
              <ul className='biggerListForDrinkHelper'>
                    <li>
                        <a className='flexer' href="/saufAlone">
                            <span className="icon fas fa-solid fa-user"></span>
                            <span className='drinkhelperText'> 1 Player</span>
                        </a>
                    </li>
                    <li>
                        <a href="/saufTwo" className='flexer'>
                            <span className="icon fas fa-solid fa-user-group"></span>
                            <span className='drinkhelperText'> 2 Players</span>
                        </a>
                    </li>
                    <li>                 
                        <a className='flexer' href="/saufwithyourfriends">
                            <span className="icon fas fa-solid fa-users"></span>
                            <span className='drinkhelperText'> 3 Players or more</span>
                        </a>
                    </li>
                </ul>
        </div>
    )
}

export default Drinkhelper