import React from 'react'
import { Link } from 'react-router-dom'


const SaufTwo = () => {
    
     return (
         <div className='mainDiv'>
            <h1>Sauf two</h1>
            <ul className='biggerListForDrinkHelper'>
                    <li>
                        <a className='flexer' href="/higherOrLower">
                            <span className="icon fas fa-solid fa-up-down"></span>
                            <span className='drinkhelperText'> Higher or Lower</span>
                        </a>
                    </li>
                    <li>
                        <a href="/beerpong" className='flexer'>
                            <span className="icon fas fa-solid fa-cubes-stacked"></span>
                            <span className='drinkhelperText'> Beerpong</span>
                        </a>
                    </li>
                    <li>
                        <a href="/laughfool" className='flexer'>
                            <span className="icon fas fa-solid fa-face-grin-tongue-wink"></span>
                            <span className='drinkhelperText'> Laughfools</span>
                        </a>
                    </li>
                    <li>
                        <a href="/holzfaellern" className='flexer'>
                            <span className="icon fas fa-solid fa-bottle-droplet"></span>
                            <span className='drinkhelperText'> Holzfällern</span>
                        </a>
                    </li>
                    <li>
                        <a href="/dontDrinkAndDrive" className='flexer'>
                            <span className="icon fas fa-solid fa-dharmachakra"></span>
                            <span className='drinkhelperText'> Don't drink and drive</span>
                        </a>
                    </li>
                    <li>
                        <a href="/quizzler" className='flexer'>
                            <span className="icon fas fa-solid fa-circle-question"></span>
                            <span className='drinkhelperText'> Quizzduell</span>
                        </a>
                    </li>
                </ul>
        </div>
    )
}

export default SaufTwo