import axios from "./http-common";

class Executer {
  async fetchWithToken(path:string) {
    return await axios
      .post("/" + path, {
        
      });
  }
  async fetchWithTokenAndBody(path:string, body:any) {
    return await axios
      .post("/" + path, body);
  }
}

export default new Executer();
