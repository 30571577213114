import React from 'react'


const LieingCards = () => {

    
     return (
         <div className='mainDiv'>
            <h1>Lieing Cards</h1>
            <p>Pretty easily played. Take a deck of cards and give every player the same amount of cards. 
                <br />
                The Goal of the game, is to lose all your cards. 
                This can be archived by putting down the cards, facing down in the right order. Every time you put a Card down, you have to say what card it should be. It "should"
                look like this: 
                <br />
                <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/2.png'}
                        alt="King"/>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/3.png'}
                        alt="King"/>
                <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/4.png'}
                        alt="King"/>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/5.png'}
                        alt="King"/>
                <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/6.png'}
                        alt="King"/>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/7.png'}
                        alt="King"/>...
                <br />
                Now here comes the catch. You are not obligated to put down the correct card. Meaning it could look like this:
                <br />
                <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/2.png'}
                        alt="King"/>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/6.png'}
                        alt="King"/>
                <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/4.png'}
                        alt="King"/>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/K.png'}
                        alt="King"/>
                <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/Q.png'}
                        alt="King"/>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/7.png'}
                        alt="King"/>...
                <br />
                At each point of the game, a player can call cap. 
                <br />
                Now the cap caller, can take a peak at the card, you just put down. If it really not the card, you had to put down, you have to take all the cards take where put down.
                If he was not right, and you did put down, what you said you put down, the cap caller has to take all the cards. 
                <br />
                <br />
                It goes without saying, but every time you take the cards you have to drink.
            </p>
        </div>
    )
}

export default LieingCards