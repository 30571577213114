import React from 'react'


function Holzfaellern(){

    const ulLines = {
        listStyleType: "circle",
    }


     return (
         <div className='mainDiv'>
            <h1>Holzfällern</h1>
            <p>Usually played 1v1 but honestly, be creative it will work with more people aswell.
                <br />
                <br />
                What you need:
                <ul style={ulLines}>
                    <li>One bottle per player</li>
                    <li>At least one more bottle cap, then there are players</li>
                </ul>
                <br />
                How to play:
                <ul style={ulLines}>
                    <li>Sit down (usually played on the floor), about two footlengts across each other</li>
                    <li>Now position your bottle between your legs, so it won't fall over</li>
                    <li>Position a bottle cap the wrong way around, on top of your bottle</li>
                    <li>Start taking turns, throwing the left over bottle caps at each others bottle</li>
                </ul>
                The Goal of the game is to knock the bottle cap of the enemy down. If it knocked down the enemy has to drink. Usually the players agree how many "hits" they can 
                take, before the bottle has to be empty.
            </p>
        </div>
    )
}

export default Holzfaellern