import React from 'react'


function LieingCubes(){
    
    
  const ulStyle = {
    listStyle: 'circle'
  };

  const pStyle ={
    margin: '1%'
  }
    
     return (
         <div>
            <h1 style={pStyle}>Lieing Cubes</h1>
            <p style={pStyle}>You will need following items:</p>
            <ul style={ulStyle}>
                <li>Two cubes</li>
                <li>Some kind of cup</li>
                <li>Something to cover the cup</li>
            </ul>
            <p style={pStyle}>
                First person start to roll the dices inside of the cup. The cup should be covered with the cardboard, or whatever is used. 
                When the player feels like it was a good roll, he turns the cup, so the dice are on the cardboard and takes a peek at what he has roled up.
                <br />
                Now the fun part. He now says what he has rolled, but he does not have to say the truth. The next player has to role something bigger. 
                If he does not believe, that the player has rolled whatever he just said he did, he can take a peek aswell, but if the first person didn't lie, the peeker has to drink.
            <br />
            </p>
            <h5 style={pStyle}>What is higher then what?</h5>
            <ul style={ulStyle}>
                <li>31, 32</li>
                <li>41, 42, 42</li>
                <li>51, 52, 53, 54</li>
                <li>61, 62, 63, 64, 65</li>
                <li>11, 22, 33, 44, 55, 66</li>
                <li>21</li>
            </ul>
            <p style={pStyle}>
                This list shows what is higher, then what. So if a player says he has thrown a 21, there is nothing higher then that. 
                <br />
                The next player could either call that it was a lie, and take a peek, or he might try to throw a 21 himself. If he manages that, he does not have to drink and the next
                person has to take the same decision.
            </p>
        </div>
    )
}

export default LieingCubes