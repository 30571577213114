import React from 'react'
import { Link } from 'react-router-dom'


const SaufAlone = () => {
    
     return (
         <div className='mainDiv'>
            <h1>Sauf alone</h1>
            <ul className='biggerListForDrinkHelper'>
                    <li>
                        <a className='flexer' href="/higherOrLower">
                            <span className="icon fas fa-solid fa-up-down"></span>
                            <span className='drinkhelperText'> Higher or Lower</span>
                        </a>
                    </li>
                    <li>
                        <a href="/vrchat" className='flexer'>
                            <span className="icon fas fa-solid fa-user-group"></span>
                            <span className='drinkhelperText'> VRChat</span>
                        </a>
                    </li>
                </ul>
        </div>
    )
}

export default SaufAlone