import React from 'react'



function RageCage(){
    const ulLines = {
        listStyleType: "circle",
    }

    const picture = {
        width: '80%'
      };
     return (
         <div className='mainDiv'>
            <h1>Rage Cage</h1>
            <p>What you need:
                <ul style={ulLines}>
                    <li>Some cups. At least 10.</li>
                    <li>At least two Beerpong (I mean PingPong) Balls</li>
                    <li>Table</li>
                </ul>
            </p>
            <h3>Setup:</h3>
            <p>
                The basic setup is pretty simple. You take a table and put your cups in the middle of the table. It doesn't really madder how the cups are aligned
                it could look something like this.
                <div className='flexer'>
                    <img style={picture} src={process.env.PUBLIC_URL + '/pic/RageCageSetup.png'} alt="Setup" />
                    This is how your setup could look like.
                </div>
                You could also decide to go nutts, and put some shots in there aswell, it doesn't really madder. It depends what yall want to drink.
                <div className='flexer'>
                    <img style={picture} src={process.env.PUBLIC_URL + '/pic/RageCageSetupBeWild.png'} alt="alt_setup" />
                </div>
            </p>
            <h3>Gameplay:</h3>
            <p>
                Now two players, that face each other (that stand across each other from the table) start. Both get a Ball. Now they take a cup from the middle, 
                toast and chug. 
                <br />
                After they finished drinking the content of the Cup, they put the cup down and start try to put the ball via a bounce from the table into the cup. 
                If the Player suceeds to put the ball into the cup, the player hands the cup with the ball to the player next to him (clockwise).
                (~ Special Rule: If a Player manages to put his ball into the cup, on his first try he can choose where he wants to put the cup)
                If the next player still has a cup, that he needs to hit, you can put your cup directly into the cup of the player next to you. His cups now go to 
                the next player in line. He has to get himself another cup from the middle, chug it, and try to catch up again. 

                <br /><br />
                If the ball hits a cup in the middle, that still has content, this cup has to be drunk.
                <br />
                It is also possible, to add a "kingcup", with something special to drink, for the last "lucky" person.
            </p>
        </div>
    )
}

export default RageCage