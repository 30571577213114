import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import "./button.css"

export default function BeeropngTeamDialog() {
  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogData] = useState(String);
  const [defaultWindow, setDefaultDialog] = useState(true);
  const [teams, setTeams] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    let currentTeams = localStorage.getItem('currentTeams')
    if(currentTeams != null){
      setTeams(JSON.parse(currentTeams))
      setDefaultDialog(false)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const regenerate = () => {
    setDefaultDialog(true)
    localStorage.removeItem('currentTeams')
  };

  function splitMeFirstAndCreateTeams(players){
    let ret = []
    if(players.includes("\n")){
      ret = teamGenerator(players.split("\n"))
    }
    else if(players.includes(",")){
      ret = teamGenerator(players.split(","))
    }
    else if(players.includes(".")){
      ret = teamGenerator(players.split("."))
    }
    else if(players.includes(" ")){
      ret = teamGenerator(players.split(" "))
    }
    else if(players.includes(";")){
      ret = teamGenerator(players.split(";"))
    }
    return ret;
  }

  function teamGenerator(unfilteredPlayers){
    let matchups = []
    let players = unfilteredPlayers.filter(item => item.trim() !== '');
    let playersAmount = players.length

      for(let i = 0; i < playersAmount; i = i+2){
          let playerOne = Math.floor(Math.random() * (players.length-1))
          let playerTwo = Math.floor(Math.random() * (players.length-1))
          while(playerTwo == playerOne && players.length > 1){
              playerTwo = Math.floor(Math.random() * (players.length))
          }
      let team1 = players[playerOne]
      let team2 = players[playerTwo]
          matchups.push({playerOne: team1, playerTwo: team2})
          players.splice(players.indexOf(team1), 1);
          players.splice(players.indexOf(team2), 1);
      }
    return matchups;
  }

  const submit = () => {
        //     localStorage.setItem('data-dark', 'true')
    let currentTeams = splitMeFirstAndCreateTeams(dialogData)
    localStorage.setItem('currentTeams', JSON.stringify(currentTeams))
    setTeams(currentTeams)
    setDefaultDialog(false)
  };


  const lightTheme = createTheme();

// @ts-ignore
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const handleValueChanged = (e) => {
    setDialogData(e.target.value);
  };

  const isDarkModeEnabled = localStorage.getItem('data-dark') === 'true';

  const theme = isDarkModeEnabled ? darkTheme : lightTheme;


  return (
    <div className='buttonForBpong'>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Teams
      </Button>
      <ThemeProvider  theme={theme}>
        <Dialog open={open} onClose={handleClose}>
            
            {defaultWindow ? (
            <>
              <DialogTitle>Who are the players?</DialogTitle>
              <DialogContent>
              <DialogContentText>
                  Write down all the players, as one word. Seperated by pretty much anything one would use to seperate two words.
              </DialogContentText>
              <br/>
              <TextField
                  autoFocus
                  multiline
                  rows={4}
                  id="players"
                  label="Spieler"
                  value={dialogData}
                  fullWidth
                  variant="outlined"
                  onChange={handleValueChanged}
              />
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={submit}>Submit</Button>
              </DialogActions>
            </>
            ) 
            : 
            (<>
              <DialogTitle>The current Teams</DialogTitle>
              <DialogContent>
              <DialogContentText>
                  The current random generated Teams are: 
              </DialogContentText>
              <ul>
                {teams.map((item, index) => (
                  <li key={index}>
                    {item.playerOne} - {item.playerTwo}
                  </li>
                ))}
            </ul>
              <br/>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={regenerate}>New</Button>
              </DialogActions>
            </>
            )}
        </Dialog>
        </ThemeProvider>    
    </div>
  );
}
