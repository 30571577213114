import React from 'react'


function Quizzler(){

     return (
         <div className='mainDiv'>
            <h1>Quizzler</h1>
            <p>Can be played with a question master. Can be played without one. <br />
            Simple premise. The players are asked a question. It can also be a astimate question. The person whose wrong, or more off has to drink.
            <br />
            The App Quizduell might also be used to battle another person, the loser of the duell has to drink.
            </p>
        </div>
    )
}

export default Quizzler