import React, { useEffect, useState, useRef } from "react";
import Vex from "vexflow";

const { Renderer, Stave, StaveNote } = Vex.Flow;

const TREBLE_NOTES = ["B3", "C4", "D4", "E4", "F4", "G4", "A4", "B4", "C5", "D5", "E5", "F5", "G5"];
const BASS_NOTES = ["C2", "D2", "E2", "F2", "G2", "A2", "B2", "C3", "D3", "E3", "F3", "G3", "A3", "B3"];

const getRandomNote = (showBass: boolean) => {
    const isTreble = showBass ? Math.random() > 0.5 : true; // If bass notes are off, always use treble
    const notes = isTreble ? TREBLE_NOTES : BASS_NOTES;
    const randomNote = notes[Math.floor(Math.random() * notes.length)];
    return { note: randomNote, clef: isTreble ? "treble" : "bass" };
};

const NoteRecognitionGame: React.FC = () => {
    const [currentNote, setCurrentNote] = useState<{ note: string; clef: string }>(getRandomNote(true));
    const [userInput, setUserInput] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [showBass, setShowBass] = useState<boolean>(true); // State to toggle bass notes visibility
    const [gameOver, setGameOver] = useState<boolean>(false); // State to track if the game is over

    const vfRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        renderNote();
    }, [currentNote]);

    useEffect(() => {
        setCurrentNote(getRandomNote(showBass)); // Update the note when showBass changes
    }, [showBass]);

    const renderNote = () => {
        if (vfRef.current) {
            vfRef.current.innerHTML = ""; // Clear the previous rendering
            const renderer = new Renderer(vfRef.current, Renderer.Backends.SVG);
            renderer.resize(700, 200);
            const context = renderer.getContext();
            const stave = new Stave(200, 40, 300);

            stave.addClef(currentNote.clef).setContext(context).draw();

            const staveNote = new StaveNote({
                clef: currentNote.clef,
                keys: [`${currentNote.note[0]}/${currentNote.note.slice(1)}`],
                duration: "q",
            });

            const notes = [staveNote];
            const voice = new Vex.Flow.Voice({ num_beats: 1, beat_value: 4 }).addTickables(notes);

            new Vex.Flow.Formatter().joinVoices([voice]).format([voice], 150);

            voice.draw(context, stave);

            const svg = vfRef.current.querySelector('svg');
            if (svg) {
                svg.style.position = 'absolute';
                svg.style.left = '0';
                svg.style.top = '0';
                svg.style.zIndex = '-1'; // Ensure SVG is in the background
                svg.style.transform = 'scale(2)'; // Adjust the scale factor
                svg.style.background = "rgb(102, 102, 102)";
            }
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (userInput.trim().toUpperCase() === currentNote.note) {
            setMessage("Correct!");
            setGameOver(false);
            setCurrentNote(getRandomNote(showBass));
            setUserInput("");
        } else {
            setMessage("Wrong! Try again.");
        }
    };

    function reloadButtion(){
        setCurrentNote(getRandomNote(showBass));
        setUserInput("");
        setMessage("");
        setGameOver(false);
    }

    const giveUp = () => {
        setMessage(`The correct note was ${currentNote.note}.`);
        setGameOver(true);
    };

    const toggleBassNotes = () => {
        setShowBass(prev => !prev);
    };

    return (
        <div style={{ position: 'relative' }}>
            <h1>What note is this?</h1>

            <div 
                ref={vfRef} 
                style={{
                    display: "inline-block",
                    position: "relative",
                    width: "700px", // Width of the SVG container
                    height: "200px" // Height of the SVG container
                }}
            ></div>

            <div style={{ position: 'relative', zIndex: 1 }}>
                <button onClick={reloadButtion}>Reload</button>
                <button onClick={giveUp} disabled={gameOver}>Give Up</button>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={userInput}
                        onChange={handleInputChange}
                        placeholder="Your answer here"
                        disabled={gameOver} // Disable input field when game is over
                    />
                    <button type="submit" disabled={gameOver}>Enter</button>
                </form>
                <button onClick={toggleBassNotes}>
                    {showBass ? "Hide Bass Notes" : "Show Bass Notes"}
                </button>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default NoteRecognitionGame;
