import React from 'react'
import "./css/kingcup.css"


const KingCup = () => {

    
     return (
         <div className='mainDiv'>
            <h1 className='grid-container'>King cup / Ring of Fire</h1>
                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/K.png'} alt="King" />
                    <p className='grid-text-kingcup'>The Ruler.<br/>Create a Rule and take as many sips as Kings were drawn. <br /> (Last king has to finish the drink.)</p>
                </div>
                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/Q.png'} alt="Queen" />
                    <p className='grid-text-kingcup'>Question Master. <br></br>
                        You can ask questions. If somebody answers, this person has to drink.
                        <br /> (Game-Questions don't count)
                    </p>
                </div>
                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/J.png'} alt="Jack" />
                    <p className='grid-text-kingcup'>Never have I ever. <br />
                    Player names a thing, that he has never done. All players that have done it, have to drink.</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/10.png'} alt="10" />
                    <p className='grid-text-kingcup'>Ten is men.
                    <br />Pretty self explanatory, don't you think? Bottoms up boiiiiz.</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/9.png'} alt="9" />
                    <p className='grid-text-kingcup'>Nine is Rhyme.
                    <br />Player starts a rhyme, the following players have to follow. First person that fails or repeats has to drink.</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/8.png'} alt="8" />
                    <p className='grid-text-kingcup'>Eight is Mate.
                    <br />I better hope, you have this special someone with you. This little cute somebody that will share every drink that follows after this card. <br />(Can be used to unmate)</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/7.png'} alt="7" />
                    <p className='grid-text-kingcup'>Seven to the Heaven. <br />Put a finger into the air, when you feel like it. Last person to follow drinks. <br />(Heavenmaster changes with next drawn 7)</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/6.png'} alt="6" />
                    <p className='grid-text-kingcup'>Six is Chicks. <br />Cheers ladies. Go nuts and take a big sip of your cocktails, as all ladies have to drink.</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/5.png'} alt="5" />
                    <p className='grid-text-kingcup'>Categories.
                    <br />Name a Categorie, like car-Brands. First person that can't name something that fits into your Categorie, or repeats something has to drink.</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/4.png'} alt="4" />
                    <p className='grid-text-kingcup'>Four you. <br />Sometimes one has to take a hit for the team. Choose somebody who has to take this hit, I mean shot.</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/3.png'} alt="3" />
                    <p className='grid-text-kingcup'>Three for me. <br />Sometimes you have to do it yourself. Just push through and take the shot.</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/2.png'} alt="2" />
                    <p className='grid-text-kingcup'>Double down. <br /> All drinks for you are doubled, until the next 2 is drawn. <br />(Doesn't count for your drinkbuddy (except he is a real one))</p>
                </div>

                <div className='pictureWithText'>
                    <img className='grid-pic-kingcup' src={process.env.PUBLIC_URL + '/pic/Ass.png'} alt="Ass" />
                    <p className='grid-text-kingcup'>Waterfall. <br />Everyone has to drink as long as the person, who got this card.</p>
                </div>
        </div>
    )
}

export default KingCup