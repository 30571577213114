import React from 'react'

const HigherOrLower = () => {

    
     return (
         <div className='mainDiv'>
            <h1 className=''>Higher or Lower</h1>
            <p>Pretty easy game. Take a deck of Cards. Each player gets a card.</p>
            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background.png'}
                                alt="King"/>
            <p>Now the fun part starts. Start drawing cards. Each player gets to answer the question. Will the drawn card, be lower or higher then the last card he received?
            </p>

            <ul>
                <li>
                    Is the player right, give away a shot.
                </li>
                <li>
                    Is the player wrong, drink a shot.
                </li>

            </ul>
        </div>
    )
}

export default HigherOrLower