import React from 'react'


const Lieing = () => {

    
     return (
         <div className='mainDiv'>
            <h1 className='grid-container'>Lieing</h1>
            <p>Can be played with either a deck of cards or cubes.</p>
            <p>Choose your passion:</p>

            
            <ul className='biggerListForDrinkHelper'>
                <li >
                    <a href="/lieingCards" className='flexer'>
                            <span className="icon fas fa-solid fa-copy"></span>
                            <span className='drinkhelperText'> Cards</span>
                        </a>
                    </li>
                    <li>
                        <a href="/lieingCubes" className='flexer'>
                            <span className="icon fas fa-solid fa-cube"></span>
                            <span className='drinkhelperText'> Cube</span>
                        </a>
                    </li>
            </ul>
        </div>
    )
}

export default Lieing