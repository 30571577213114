import React, { Component } from "react";
import "./css/music.css"


export default class Music extends Component {
  constructor(props:any) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="spotifyDiv">
        {/* <iframe src="https://open.spotify.com/embed/playlist/61bo6vn4mNggSaPNhFFdmT?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0"  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}
          <center className="spotifyCenterer">
            <iframe src="https://open.spotify.com/embed/playlist/61bo6vn4mNggSaPNhFFdmT?utm_source=generator&theme=0" width="100%" height="100%" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </center>
        </div>
    );
  }
}
