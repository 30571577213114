import React, { useState,useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography } from '@mui/material/';
import executer from '../../services/executer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./css/beerpong.css"
import BeeropngTeamDialog from './beerpong/beerpongCreateTeamDialog';

interface Rule {
  rulename:string,
  rule:string,
  origin:string
}

const rows = [
  { rulename: 'Rule 1', rule: 'Some rule', origin: 'User' },
  { rulename: 'Rule 2', rule: 'Another rule', origin: 'System' },
  { rulename: 'Rule 3', rule: 'A third rule', origin: 'User' },
  { rulename: 'Rule 4', rule: 'One more rule', origin: 'System' },
]


const EnhancedTable = () => {
  const [checkedItems, setCheckedItems] = useState<any>({});
  const [origin, setOrigin] = useState<string[]>([])
  const [rules, setRules] = useState<Rule[]>(rows)
  
  useEffect(() =>{
    executer.fetchWithToken("saufdings/beerpong/rules").then((response:any) => {
      let rows:Rule[] = response.data
      setRules(rows)
    
    } ) 
    executer.fetchWithToken("saufdings/beerpong/rules/places").then((response:any) => {
      let rows:Rule[] = response.data
      let origins:string[] = []
      rows.forEach(element => {
        origins.push(element.origin)
      });
      setOrigin(origins)
      setCheckedItems(origins)
    } ) 
  }, [])


  function CollapsibleCheckboxList() {

    const handleToggle = (value:any) => () => {
      setCheckedItems({ ...checkedItems, [value]: !checkedItems[value] });
    };
  
    return (
      <Accordion className='listThingy tableBorderBeerpongRules'>
        <AccordionSummary expandIcon={<ExpandMoreIcon className='tableText' />}>
          <Typography>Ruleset</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <List dense>
          {origin.map((or:any) => (
            
              <ListItem key={or}>
                <ListItemIcon>
                  <Checkbox className='checkboxThingy'
                    checked={!checkedItems[or]}
                    onChange={handleToggle(or)}
                    color="primary"
                    />
                </ListItemIcon>
                <ListItemText primary={or} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  }


  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('rulename');

  const handleSort = (property:any) => (event:any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array:any, comparator:any) => {
    const stabilizedThis = array.map((el:any, index:any) => [el, index]);
    stabilizedThis.sort((a:any, b:any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el:any) => el[0]);
  };

  const getComparator = (order:any, orderBy:any) => {
    return order === 'desc'
      ? (a:any, b:any) => descendingComparator(a, b, orderBy)
      : (a:any, b:any) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a:any, b:any, orderBy:any) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const sortedRows = stableSort(rules, getComparator(order, orderBy));

  return (
    <div className='mainDiv'>
      <div>
        <div className='h3WithButton'>
          <h3 className='h3ForBpong'>Grundregeln</h3>
          <BeeropngTeamDialog></BeeropngTeamDialog>
        </div>
        <p>Es ist Sinnvoll vor bevor das Spiel beginnt zu vereinbaren, mit welchen Regeln man spielen möchte. Mit dem Folgenden ist es möglich Regeln ein und auszublenden.</p>
        <p>Basics. <br></br>Trifft ein Ball, muss der Becher getrunken werden. Ob der Becher vor dem nächsten Wurf getrunken werden muss, ist auszumachen. Wird ein noch nicht ausgetrunkener Becher getroffen, muss dieser getrunken werden.</p>
        <p>Um zu Entscheiden, wer anfängt gibt es zwei Standard Möglichkeiten. Schere, Stein, Papier oder auswerfen. <br></br>Beim Auswerfen empfielt sich das sogenannte Eye-to-Eye. Dabei wirft jeweils ein Spieler aus jedem Team einen Ball. Während des Wurfes schaut man seinem Gegner in die Augen. Der treffende Entscheidet wer anfängt.</p>
      </div>
      <div>
       <CollapsibleCheckboxList></CollapsibleCheckboxList>
      </div>
        <div className='tableBorderBeerpongRules'>
          <Table>
            <TableHead>
                <TableRow key="Headers">
                  <TableCell className='ruleColumn' key="RulenameColumn">
                      <TableSortLabel active={orderBy === 'rulename'}  onClick={handleSort('rulename')} className='tableTextHead'>
                      Rulename
                      </TableSortLabel>
                  </TableCell>
                  <TableCell key="RuleColumn">
                      <TableSortLabel active={orderBy === 'rule'}  onClick={handleSort('rule')} className='tableTextHead'>
                      Rule
                      </TableSortLabel>
                  </TableCell>
                </TableRow>
            </TableHead>
          </Table>
          {
          Object.keys(checkedItems).map((origin: string) => {
            const rowsForOrigin = sortedRows.filter((row: any) => row.origin == checkedItems[origin]);
            if (!checkedItems[origin] || rowsForOrigin.length === 0 || checkedItems[checkedItems[origin]]) {
              return null;
            }
            return (
              <div key={origin + ".div"}>
                <h4 className='tableTextHead' key={origin + ".tableTextHead"}>{checkedItems[origin]}</h4>
                <div className='tableBorderBeerpongRules tableBorderBeerpongRulesInner'>
                  <Table key={origin + "0"}>
                    <TableBody key={origin + "1"}>
                      {rowsForOrigin.map((row: any) => (
                        <TableRow key={row.rulename}>
                          <TableCell key={row.rulename + ".name"} className='tableText ruleColumn'>{row.rulename}</TableCell>
                          <TableCell key={row.rulename + ".rule"} className='tableText'>{row.rule}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            );
          })
        }
        </div>
      </div>
  );
};

export default EnhancedTable;
