import React from 'react'
import "./css/shitty.css"

const ShittyGame = () => {

    return ( 
        <div className='mainDiv'>
            <h1 className='alignerHeader'>Shitty</h1>
            <div className='grid-container'>
                <div className="grid-pic1">
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/Background_1.png'}
                        alt="King"/>
                </div>
                <div >
                    <p className='shittyText2_0'>Red or Black?
                    <br />Correct: Give one shot to another player
                    <br />Incorrect: Drink one shot yourself</p>
                </div>

                <div className='grid-pic1'>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/Background_2.png'}
                        alt="King"/>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/Background_2.png'}
                        alt="King"/>
                </div>
                <div className='grid-text'>
                    <p className='shittyText2_0'>Under or above? (Or same)
                    <br />Correct: Give two shots to another player
                    <br />Incorrect: Drink two shots yourself</p>
                </div>

                <div className="grid-pic1">
                    <div>
                        <img
                            className='pic'
                            src={process.env.PUBLIC_URL + '/pic/Background_3.png'}
                            alt="King"/>
                        <img
                            className='pic'
                            src={process.env.PUBLIC_URL + '/pic/Background_3.png'}
                            alt="King"/>
                    </div>
                    <div>
                        <img
                            className='pic'
                            src={process.env.PUBLIC_URL + '/pic/Background_3.png'}
                            alt="King"/>
                    </div>
                </div>
                <div className='grid-text'>

                    <p className='shittyText2_0'>Between or outside? (Or same)
                    <br />Correct: Give three shots to another player
                    <br />Incorrect: Drink three shots yourself</p>
            </div>
                <div className="grid-pic1">
                    <div>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/Background_4.png'}
                        alt="King"/>
                    <img
                        className='pic'
                        src={process.env.PUBLIC_URL + '/pic/Background_4.png'}
                        alt="King"/>
                    </div>
                    <div>
                    <img
                        className='grid-pic1 pic'
                        src={process.env.PUBLIC_URL + '/pic/Background_4.png'}
                        alt="King"/>
                    <img
                        className='grid-pic1 pic'
                        src={process.env.PUBLIC_URL + '/pic/Background_4.png'}
                        alt="King"/>
                </div>
                </div>
                <div className='grid-text'>
                    <p className='shittyText2_0'>Red or Black
                    <br />Correct: Give four shots to another player
                    <br />Incorrect: Drink four shots yourself</p>
                </div>
            </div>
            <div>
                <div>
                    <h4 className='alignerHeader'>Endgame</h4>
                    <p className='aligner'>Take 9 new Cards from the deck and align them like this: </p>
                    <div className='allDiv'>
                        <div className='top-div'>
                            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background_1.png'}
                                alt="King"/>
                            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background_2.png'}
                                alt="King"/>
                            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background_3.png'}
                                alt="King"/>
                            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background_4.png'}
                                alt="King"/>
                        </div>
                        <div className='bottom-div'>
                            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background_1.png'}
                                alt="King"/>
                            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background_2.png'}
                                alt="King"/>
                            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background_3.png'}
                                alt="King"/>
                            <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background_4.png'}
                                alt="King"/>
                        </div>

                    </div>
                    <div className='middle-div'>
                        <img
                                className='pic'
                                src={process.env.PUBLIC_URL + '/pic/Background.png'}
                                alt="King"/>
                    </div>
                </div>
                
                <div className='shittyText'>
                    <p>
                        Now name one row the evil row, and the other row the good row.<br/><br/>
                        Start by turning around one of the lower cards, either from the good or the evil row. If somebody has a card with the same value they can place the card right now. Is the
                        card placed on the good side, the placer may give away as many shots as the card-pairs have been turned. Is the card placed in the evil row, the placer has to drink 
                        the shots instead of giving them away. 

                        <br/><br/>
                        One might wonder. Why should I place my card in the evil row? Well good question, smart ass. Simple answer is to be saved in the end. 
                        At the end of the game, the players have to take their cards, and sum up, what the cards say. Everything higher then a 10, counts as a 10.<br/>
                        Now the sum has to be drunken. So if a player got really lucky (or rather unlucky) he might have all the Kings on his hand. Meaning he would have to drink
                        40 shots right now. 

                        <br/><br/>
                        To finish the game, the last card has to be turned. Whatever this card says, has to be drunken by all the players.
                    </p>
                </div>
            </div>

        </div> 
    )
}

export default ShittyGame