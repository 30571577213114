import React from 'react'


function Laughfool(){
    const ulStyle = {
        listStyle: 'circle'
      };

     return (
         <div className='mainDiv'>
            <h1>Laughfool</h1>
            <p>Pretty easy. You laugh, you loose.
                <br />
                All players look up some jokes. Tell those to each other. Everytime you laugh, you gotta drink.
                <br />
                Possible restrictions:
                <ul style={ulStyle}>
                    <li>Joke-length</li>
                    <li>Joke-kind (Mother, father, dark, family friendly, dirty)</li>
                </ul>
                <br />
                <br />
                Other alternative:
                <br />
                Watch a funny Video or Movie and drink every time you laugh.
            </p>
        </div>
    )
}

export default Laughfool