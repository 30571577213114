import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
// document.body.style.backgroundColor = '#302f2f';
// @ts-ignore
const root = createRoot(container);

const newFavicon = './logo.png';

// Find the favicon link element
const favicon = document.querySelector('link[rel="shortcut icon"]') ||
                document.querySelector('link[rel="icon"]');

if(favicon){ // @ts-ignore
  favicon.href = newFavicon
}
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

serviceWorker.unregister();
