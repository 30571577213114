import React, {useEffect} from 'react'
import "./css/Navbar.css"


// Navbar created by  @jodacame https://codepen.io/jodacame/pen/qBPXpYr and modified by @Franzderpalme

const Navbar = () => {
    const toggle = function(data:any){
        let toSet = document.body.dataset[data] === "true" ? "false" : "true";
        document.body.setAttribute(`data-${data}`, toSet)
        localStorage.setItem(`data-${data}`,toSet)
    }

    const toggleData = function(data:string, state:any){
        document.body.setAttribute(`data-${data}`, state)
    }
    useEffect(() => {
        let darkmode = localStorage.getItem(`data-dark`);
        if(darkmode == null){
            localStorage.setItem('data-dark', 'true')
            localStorage.setItem('data-toggled', 'false')
        }
        darkmode = localStorage.getItem(`data-dark`);
        let toggled = localStorage.getItem(`data-toggled`);
        toggleData("dark", darkmode)
        toggleData("toggled", toggled)

        const menu = document.querySelector('.menu');
        const darkMode = document.querySelector('.dark-mode');
        //@ts-ignore
        menu.onclick = function(){
            toggle('toggled')
        }
        //@ts-ignore
            darkMode.onclick = function(){
             toggle('dark')
        }
    }, [])

     return (
        <div>
            <ul id="sidebar">
                <li className="no-link brand">
                <a href="/">
                    <div className='titleAligner'>
                            <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Dowtex" />
                        <span className="title">Dowtex</span>
                    </div>
                </a>
                </li>
                <li>
                <a href="/">
                    <span className="icon fas fa-tachometer-alt"></span>
                    <span className="title">Drinkhelper</span>
                </a>
                </li>
                <li>
                <a href="/beerpong">
                    <span className="icon fas fa-cubes-stacked"></span>
                    <span className="title">Beerpong</span>
                </a>
                </li>
                <li>
                <a href="/kingcup">
                    <span className="icon fas fa-solid fa-crown"></span>
                    <span className="title">Kingcup</span>
                </a>
                </li>
                <li>
                <a href="/shitty">
                    <span className="icon fas fa-solid fa-copy"></span>
                    <span className="title">Shitty</span>
                </a>
                </li>
                <li>
                    <a href="/music">
                        <span className="icon fas fa-solid fa-music"></span>
                        <span className="title">Music</span>
                    </a>
                </li>
                
                <li>
                    <a href="/saufTwo">
                        <span className="icon fas fa-solid fa-user-group"></span>
                        <span className='title'> 2 Players</span>
                    </a>
                </li>
                <li>                 
                    <a href="/saufwithyourfriends">
                        <span className="icon fas fa-solid fa-users"></span>
                        <span className='title'> 3 Players or more</span>
                    </a>
                </li>
                <li>                 
                    <a href="/sheetMusic">
                        <span className="icon fas fa-sheet-plastic"></span>
                        <span className='title'> Learn notes</span>
                    </a>
                </li>

                <li className='no-link versionListPoint'>
                    <p className='versionTeller'>Version 2.1.1</p>
                </li>
            </ul>
            <div className="container">
                <div className="toolbar">
                <a href="#" className="menu">
                    <span className="fa fa-bars"></span>
                </a>
                
                <a href="#" className="dark-mode">
                    <span className="fa fa-adjust"></span>
                </a>
                </div>
            </div>
    </div>
    )
}

export default Navbar